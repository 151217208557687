import "../css/TsHomePage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";
import uptiq from "../assets/images/ideathon/UptiqLogoCropped.png";


export default function SponserSlider() {
  let isMobile = useMediaQuery({ query: "(max-width:700px)" });
  let isSmallMobile = useMediaQuery({ query: "(max-width:645px)" });
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isSmallMobile ? 1 : isMobile ? 1 : 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Slider {...settings}>
        <div className="sponsImage">
          <img src={uptiq} className="uptiqSponser" alt="" />
        </div>
      </Slider>
    </>
  );
}
