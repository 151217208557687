import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../css/signUp/SignUp.css";
import "../css/login/Login.css";
import "../css/signUp/SignUpMediaQuery.css";
import SignUpInput from "./SignUpInput";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GreenTickMark from "../assets/images/forgotPassword/GreenTickMark.png";
import { Loader } from "./Loader";

export default function ForgotPasswordForm() {
  const [inputs, setInputs] = useState({
    //initial state of the form
    email: "",
  });

  const [loader, setLoader] = useState(false); //for displaying the loader

  const [success, setSuccess] = useState(false); //state to check if the login is successful

  const notifySuccess = (
    e //function to display the success messages
  ) =>
    toast.success(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notify = (
    e //function to display the error messages
  ) =>
    toast.error(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleChange = (event) => {
    //function to handle the change in the input fields
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const maxLengthCheck = (object) => {
    //function to check the maximum length of the input fields
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    //function to handle the submit event
    e.preventDefault();
    const { email } = inputs;

    //if the button is not clicked
    if (email === "") {
      //if the email field is empty
      notify("Please enter your email id");
      setLoader(false);
      return;
    }
    const body = JSON.stringify({ email });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        //post request to the backend
        "https://ieeevitpune.com/" + "user/forgotPassword",
        body,
        config
      );
      if (response.data.success === true) {
        //if the login is successful
        notifySuccess(response.data.msg); //display the success message
        // console.log(response);
        setSuccess(true);
      } else {
        //if the login is unsuccessful
        notify(response.data.msg); //display the error message
      }
    } catch (err) {
      notify(err.response.data.msg);
    }
    setLoader(false);
  };

  return (
    <>
      {loader ? <Loader /> : ""}
      <div className="loginForm">
        <div className="SFtitle FPtitle">Forgot Password</div>
        <form
          onSubmit={handleSubmit}
          className={!success ? "form" : "form hide"}
        >
          <SignUpInput
            name={"email"}
            labelName={"Email Id"}
            type={"email"}
            className={"lemail"}
            value={inputs?.email}
            onChange={handleChange}
            maxLengthCheck={maxLengthCheck}
          />
          <div className="submitContainer">
            <button className="submit" type="submit">
              SUBMIT
            </button>
          </div>
          <div className="member">
            Don't have an account?{" "}
            <Link to="/signUp" className="SUlink">
              Sign up
            </Link>
          </div>
          <div className="verifyContainer">
            <Link to="/loginUsingOtp" className="SUlink">
              Login using OTP
            </Link>
          </div>
        </form>
        <div className={!success ? "hide" : ""}>
          <p className="FPreset">
            A reset password link has been sent to your email!
          </p>
          <div className="greenTickContainer">
            <img
              src={GreenTickMark}
              alt="Green Tick Mark"
              className="greenTick"
            />
          </div>
        </div>
      </div>
    </>
  );
}
