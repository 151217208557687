import React from "react";

export default function UserProfileInput(props) {
  let {
    labelName,
    name,
    type,
    maxLengthCheck,
    maxLength,
    onKeyDown,
    handleChange,
    value,
    onFocus,
    onBlur,
    edit,
  } = props;

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <div>
      <label className="UDtitle">{labelName}: </label>
      <input
        className={!edit ? `UDinput UD${name}` : `UDinput edit UD${name}`}
        type={type}
        name={name}
        onChange={handleChange}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={!edit}
        maxLength={maxLength}
        onWheel={numberInputOnWheelPreventChange}
        onInput={maxLengthCheck}
        onKeyDown={onKeyDown}
        autoComplete="off"
        placeholder={`* Please fill this field`}
      />
    </div>
  );
}

UserProfileInput.defaultProps = {
  type: "text",
  maxLengthCheck: () => {},
  onKeyDown: () => {},
  maxLength: "30",
};
