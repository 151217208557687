import React, { useState } from "react";
import "../css/Timeline.css";
import Timeline from "./Timeline";

export default function Agenda() {
  const [day, setDay] = useState(1);

  const [date, setDate] = useState("23rd February 2025");

  const handleDay = (day) => {
    setDay(day);
    if (day === 1) setDate("23rd February 2025");
    else if (day === 2) setDate("1st April 2023");
    else setDate("2nd April 2023");
  };

  return (
    <div className="agenda">
      <div className="agendaContent">
        <div className="agendaHeader">
          <p>THE AGENDA</p>
        </div>
        <div className="agendaBody">
        Day 1 kicks off with the highly anticipated AI Innovators Hackathon, setting the stage for groundbreaking ideas and next-gen AI solutions!
        </div>
        <div className="TSAbtnContainer">
          <button
            className={day === 1 ? "TSAbtn" : "TSAbtn TSAbtnActive"}
            onClick={() => handleDay(1)}
          >
            Day 1
          </button>
          {/* <button
            className={day === 2 ? "TSAbtn" : "TSAbtn TSAbtnActive"}
            onClick={() => handleDay(2)}
          >
            Day 2
          </button>
          <button
            className={day === 3 ? "TSAbtn" : "TSAbtn TSAbtnActive"}
            onClick={() => handleDay(3)}
          >
            Day 3
          </button> */}
        </div>
        <p className="TLeventTime AgendaShowDate">{date}</p>
      </div>
      <div className="timelineContainer">
        <Timeline day={day} />
      </div>
    </div>
  );
}
