import { Canvas, useFrame } from "@react-three/fiber";
import { useRef, useMemo } from "react";
import * as THREE from "three";

const SpaceBackground = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
      }}
    >
      <Canvas camera={{ position: [0, 0, 5] }}>
        <AnimatedStars count={5000} />
      </Canvas>
    </div>
  );
};

const AnimatedStars = ({ count }) => {
  const mesh = useRef();
  const { positions, velocities } = useMemo(() => {
    const positions = new Float32Array(count * 3);
    const velocities = new Float32Array(count * 3);

    for (let i = 0; i < count * 3; i++) {
      positions[i] = (Math.random() - 0.5) * 200; // Spread stars randomly
      velocities[i] = (Math.random() - 0.5) * 0.001; // Tiny movement speed
    }

    return { positions, velocities };
  }, [count]);

  useFrame(() => {
    if (mesh.current) {
      for (let i = 0; i < positions.length; i += 3) {
        // Add smooth motion
        positions[i] += velocities[i] * 5; // Move in X
        positions[i + 1] += velocities[i + 1] * 5; // Move in Y
        positions[i + 2] += velocities[i + 2] * 5; // Move in Z

        // Reset when they go too far
        if (positions[i] > 100 || positions[i] < -100) velocities[i] *= -1;
        if (positions[i + 1] > 100 || positions[i + 1] < -100) velocities[i + 1] *= -1;
        if (positions[i + 2] > 100 || positions[i + 2] < -100) velocities[i + 2] *= -1;
      }

      mesh.current.geometry.attributes.position.needsUpdate = true;
    }
  });

  return (
    <points ref={mesh}>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          attach="attributes-position"
          array={positions}
          count={count}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial attach="material" color="white" size={0.2} />
    </points>
  );
};

export default SpaceBackground;
