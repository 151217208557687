import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom"; // ✅ Import useNavigate
import SpaceBackground from "../components/SpaceBackground";
import "../css/LandingPage.css";
import SponserSlider from "../components/SponserSlider";
import Agenda from "../components/Agenda";
import { Link } from "react-router-dom";
import TsNavbar from "../components/TsNavbar";
import workshopImage from "../assets/images/homePage/WorkshopImage.jpg";
import ideathonImage from "../assets/images/homePage/ideathonImage2.png";
import CSI from "../assets/images/workshop/CSI.png";
import uptiq from "../assets/images/ideathon/UptiqLogoCropped.png";



const textVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (delay) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 1, delay }
  }),
};
const textColor = "rgb(6, 11, 40)"; 
const LandingPage = () => {
  const navigate = useNavigate(); // ✅ Define navigate function

  return (
    <div style={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#0a0a2a",
      position: "relative",
    }}>
      <SpaceBackground />

      {/* Text Container */}
      <div className="ieeepresents" style={{ textAlign: "center", color: "white", padding: "100px 20px", marginTop: "150px" }}>
        <motion.div variants={textVariants} initial="hidden" animate="visible" custom={0.5} style={{ fontSize: "2rem", fontWeight: "400" }}>
          IEEE Presents
        </motion.div>

        <motion.div variants={textVariants} initial="hidden" animate="visible" custom={1} style={{ fontSize: "6rem", fontWeight: "bold", margin: "10px 0" }}>
          TECH SUMMIT 2025
        </motion.div>

        <motion.div variants={textVariants} initial="hidden" animate="visible" custom={1.5} style={{ fontSize: "3rem", fontWeight: "500", fontStyle: "italic" }}>
          Architects of Tomorrow
        </motion.div>
      </div>
      <div className="containerTs">
        {/* <div className="gradient3"></div> */}
        {/* <div className="gradient"></div>
        <div className="gradient2"></div> */}
        {/* <div className="gradient4"></div> */}
    
      
        <div className="TechsummitSession1Container">
  <div className="TechsummitSession1">
    <div className="TechsummitSession1Heading">TECH SUMMIT'25</div>
    <div className="TechsummitSession1SubHeading">
      <ul>
        <li>
          Tech Summit is the <b className="TSS1b">annual flagship event</b> of IEEE, bringing together visionaries, developers, and tech enthusiasts under one roof!
        </li>
        <li>
          This year's theme, <b className="TSS1b">"Architects of Tomorrow"</b>, explores how emerging technologies shape our world today.
        </li>
        <li>
          Experience the thrill of an <b className="TSS1b">AI Innovators Hackathon</b>, sponsored by UPTIQ, hosted on their AI Agent platform.
        </li>
        <li>
          Compete in <b className="TSS1b">CodeZest</b>, the official competitive coding contest of VIT, designed to challenge and elevate your problem-solving skills.
        </li>
        <li>
          A perfect blend of <b className="TSS1b">Development and DSA</b>, two core pillars of the software industry, ensuring you level up in both domains.
        </li>
        <li>
          Take away <b className="TSS1b">unforgettable memories</b>, loads of learning, and the chance to network with like-minded peers.
        </li>
        <li>
          🚀 Get ready to be a part of <b className="TSS1b">Tech Summit 25</b> – where innovation meets inspiration! 🔥
        </li>
      </ul>
    </div>
  </div>
</div>
            {/* <div className="TechsummitSession1BtnContainer">
              <a
                href="https://bit.ly/IEEE_TechSummit23"
                target="_blank"
                rel="noreferrer"
              >
                <button className="TSS1button">Register Now</button>
              </a>
            </div> */}
          
        {/* <div className="line"></div>
        <div className="headingSpeakers">TECH TALKS</div>
        <div className="subHeading">
          Join us for this event featuring a lineup of experts in the tech
          industry share their knowledge, experience, and insights.
        </div> */}

        {/* Domains Card Slider  */}
        {/* <div className="domainSlider">
          <SliderTs />
        </div>
        <div className="TechsummitSession3BtnContainer">
          <Link to="/TechSummit23/TechTalks">
            <button className="TSS3button">See More</button>
          </Link>
        </div>
        <div className="line"></div>
        <TSPanel />
        <div className="line"></div>

        <div className="TechsummitSession2Container">
          <div className="TechsummitSession2">
            <div className="TSWflexConainer1">
              <img src={CSI} className="CSILogo" alt="" />
              <div className="CSIPresents">Presents</div>
              <div className="TechsummitSession2Heading">WORKSHOP</div>
            </div>
            <div className="TechsummitSession2SubHeading">
              <img src={workshopImage} className="TSS2image" alt="" />
              <p className="TSS2p">
                The Digital Forensics workshop aims to provide attendees with a
                comprehensive understanding of Digital Forensics. The workshop
                is designed for learning the principles, tools, and techniques
                used in the field of Digital Forensics. The workshop will be led
                by experienced professionals in the field of Digital Forensics,
                who will share their knowledge and expertise with attendees.
              </p>
            </div>
            <div className="TechsummitSession2BtnContainer">
              <Link to="/TechSummit23/Workshop">
                <button className="TSS2button">See More</button>
              </Link>
            </div>
          </div>
        </div> */}
        <div className="line"></div>
        <div className="TechsummitSession3Container">
          <div className="TechsummitSession3">
            <div className="TSWflexConainer2">
              <img src={uptiq} className="uptiqLogo" alt="" />
              <div className="uptiqPresents">Presents</div>
              {/* <div className="TechsummitSession3Heading">HACKATHON</div> */}
            </div>
            {/* <div className="TechsummitSession3SubHeading">
              <p className="TSS3p">
                Attention all innovators! Join us for our upcoming ideathon
                event focused on some of the most critical domains of our time.
                This is your chance to work collaboratively with experts and
                peers to develop innovative solutions that can make a real
                difference in the world. Don't miss this opportunity to be part
                of something truly impactful. Register now and let your
                creativity shine!
              </p>
              <img src={ideathonImage} className="TSS3image" alt="" />
            </div> */}
            
      {/* Events Section */}
      <motion.div 
        initial={{ opacity: 0, y: 50 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ duration: 1, delay: 2 }} 
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
          padding: "50px 20px",
          marginBottom: "100px", // Ensures space before the footer
        }}
      >
        <EventCard 
          title="AI Innovators Hackathon"
          description="Join the AI Innovators Hackathon, sponsored by UPTIQ.AI, and bring your AI-driven ideas to life on their cutting-edge AI agents platform! This is your chance to innovate, compete, and connect with AI enthusiasts and industry experts."
          date=" ₹60,000"
          members="2 to 4"
          buttonText="Know More"
          onClick={() => navigate("/TechSummit25/Hackathon")} // ✅ Pass navigate function
        />
         <div className="line"></div>
        <EventCard 
          title="CodeZest"
          description="Coming Soon..."
          date="TBA"
          members="TBA"
          buttonText="Stay Tuned"
        />
      </motion.div>

    
            {/* <div className="TechsummitSession3BtnContainer">
              <Link to="/TechSummit23/Ideathon">
                <button className="TSS3button">See More</button>
              </Link>
            </div> */}
          </div>
        </div>

        {/* <div className="line"></div> */}

        {/* <div className="heading1">IEEE EXPO</div>

        <p className="subHeading1">
          Join us at the exposition featuring numerous companies showcasing
          their products as well as latest innovations and technologies.{" "}
        </p>
        <CompanyCards />

        <div className="TechsummitSession3BtnContainer">
          <Link to="/TechSummit23/Expo">
            <button className="TSS3button">See More</button>
          </Link>
        </div> */}
        <div className="line"></div>
        <div>
          <Agenda />
        </div>
        The Agenda 
        {/* The Agenda  */}
        <div className="line"></div>
        {/* Our Sponsers  */}
        <h1 className="heading5">OUR SPONSORS</h1>
        <p className="subDetailsSponsers">
          Thanks to our partners for sponsoring this event!
        </p>
        <div className="sponsSlider">
          <SponserSlider />
        </div>
        {/* Our Sponsers */}
        <div className="line"></div>
        {/* The Venue  */}
        <h1 className="heading6">VENUE</h1>
        <div className="venueContent">
          <div className="mapsImage">
            <iframe
              title="Map of Vishwakarma Institute of Technology"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.442128793462!2d73.86601501484182!3d18.46362188744226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ea950f616219%3A0x321bdae2cea9f064!2sVishwakarma%20Institute%20of%20Technology!5e0!3m2!1sen!2sin!4v1677335727696!5m2!1sen!2sin"
              width="585"
              height="385"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
          <p className="collegeAddress">
            Vishwakarma Institute of Technology at 666, Upper Indiranagar,
            Bibwewadi, Pune - 411 037.
          </p>
        </div>
        </div>
</div>
  
  );
};

const EventCard = ({ title, description, date, members, buttonText, onClick }) => {
  return (
    <motion.div 
      whileHover={{ scale: 1.05 }} 
      whileTap={{ scale: 0.95 }} 
      style={{
        backgroundColor: "#060b27",
        border: "5px solid #283e5f",   
        backdropFilter: "blur(10px)",
        padding: "20px",
        borderRadius: "12px",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "white",
        border: "1px solid rgba(255, 255, 255, 0.3)"
      }}
    >
      <h2 style={{ fontSize: "2rem", marginBottom: "10px" }}className="ieeepresents">{title}</h2>
      <p style={{ textAlign: "center", fontSize: "1rem", opacity: 0.8 }}>{description}</p>
      <p style={{ marginTop: "10px", fontSize: "1rem" }}><strong className="ieeepresents"> Prize Pool:</strong> {date}</p>
      <p style={{ marginTop: "5px", fontSize: "1rem" }}><strong className="ieeepresents">Team Members:</strong> {members}</p>
      
      <motion.button 
        whileHover={{ scale: 1.1 }} 
        whileTap={{ scale: 0.9 }} 
        onClick={onClick} // ✅ Use onClick properly
        style={{
          marginTop: "15px",
          padding: "10px 20px",
          borderRadius: "8px",
          color: "white",
          fontSize: "1rem",
          border: "none",
          cursor: "pointer"
        }}
    className="button"
      >
        {buttonText}
      </motion.button>
    </motion.div>
  );
 
};

export default LandingPage;
