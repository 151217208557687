import React from "react";
import "../css/aboutUs/Vision.css";
import teamimage from "../assets/images/aboutUs/TeamImage.jpg";
// import teamimage from "../assets/images/aboutUs/AboutUs2.jpeg";

function Vision() {
  return (
    <div className="vision">
      <img className="teamimage" src={teamimage} alt="" />
      {/* <div className="about-us"><b>ABOUT US</b></div> */}
      {/* <div className="line"></div> */}
      <p className="heading-1">
        <b className="heading-1b">OUR VISION</b>
      </p>
      <p className="desc">
        At IEEE VIT Pune, our main goal is to share our passion for technology
        by educating students about current development in the field of
        engineering by enhancing their knowledge in the latest technologies via
        organizing technical workshops and webinars. We promote our student
        community with their ability for invention, resourcefulness and
        ultimately innovation.
      </p>
      {/* <div className="rectangle1"></div>
            <div className="rectangle2"></div> */}
    </div>
  );
}

export default Vision;
