import React from "react";

export default function DomainInfo(props) {
  return (
    <div className="DIMcardContainer">
      <div className="DIMcard">
        <h1 className="DIMtitle">DOMAINS</h1>
        <h1 className="DIMtitle DIMtitle2">{props.state.text}</h1>
        <p className="DIMinfo">{props.state.info}</p>
      </div>
    </div>
  );
}
