import React from "react";
import SpaceBackground from "../components/SpaceBackground";
import Particle from "../../components/Particle";
import HackathonRegistrationForm from "../components/HackathonRegistrationForm";
import "../css/HackathonRegistrationForm.css"
export default function HackathonRegistration() {
  return (
    <div className="hackathonSignUp">
      <SpaceBackground />
      <Particle />
      <HackathonRegistrationForm />
    </div>
  );
}