import React, { useState } from "react";
import axios from "axios";
import "../css/HackathonRegistrationForm.css";
import "../css/HackathonRegistrationMediaQuery.css";
import SignUpInput from "../../components/SignUpInput";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function HackathonRegistrationForm() {
  const [inputs, setInputs] = useState({
    teamName: "",
    groupLeaderName: "",
    leaderEmail: "",
    leaderContact: "",
    teamMembers: [{ name: "", email: "", contact: "" }],
    college: "",
    branch: "",
    year: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const years = ["First Year", "Second Year", "Third Year", "Final Year"];
  const colleges = ["VIT", "VIIT", "PICT", "COEP", "PVG", "MMCOE", "CUMMINS", "AISSMS", "PCCOE", "OTHER"];

  const handleChange = (event, index = null) => {
    const { name, value } = event.target;
    if (index !== null) {
      const updatedMembers = [...inputs.teamMembers];
      updatedMembers[index][name] = value;
      setInputs((prev) => ({ ...prev, teamMembers: updatedMembers }));
    } else {
      setInputs((prev) => ({ ...prev, [name]: value }));
    }
  };

  const addMember = () => {
    if (inputs.teamMembers.length < 3) {
      setInputs((prev) => ({
        ...prev,
        teamMembers: [...prev.teamMembers, { name: "", email: "", contact: "" }],
      }));
    } else {
      alert("You can add only up to 3 team members.");
    }
  };

  const removeMember = (index) => {
    const updatedMembers = inputs.teamMembers.filter((_, i) => i !== index);
    setInputs((prev) => ({ ...prev, teamMembers: updatedMembers }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post("https://ieeevitpune.com/tshackathon", inputs);
      if (response.data.success === false) {
        toast.error(response.data.msg, {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
        });
      } else {
        toast.success("Registration successful!", {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
        });

        // Reset form after successful submission
        setInputs({
          teamName: "",
          groupLeaderName: "",
          leaderEmail: "",
          leaderContact: "",
          teamMembers: [{ name: "", email: "", contact: "" }],
          college: "",
          branch: "",
          year: "",
          message: "",
        });

        navigate("/"); // Redirect after registration
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
      });
      console.error("Error submitting form:", error);
    }

    setLoading(false);
  };

  return (
    <div className="signUpForm">
      <div className="SFtitle">AI Innovators Hackathon – Build with AI Agents!</div>
      <form className="form" onSubmit={handleSubmit}>
        <SignUpInput name="teamName" labelName="Team Name" value={inputs.teamName} onChange={handleChange} required />

        {/* Group Leader Details */}
        <div className="SUflexContainer">
          <SignUpInput name="groupLeaderName" labelName="Leader Name" value={inputs.groupLeaderName} onChange={handleChange} required />
          <SignUpInput name="leaderEmail" labelName="Leader Email" type="email" value={inputs.leaderEmail} onChange={handleChange} required />
          <SignUpInput name="leaderContact" labelName="Leader WhatsApp Number" type="tel" value={inputs.leaderContact} maxLength="10" onChange={handleChange} required />
        </div>

        {/* College and Branch */}
        <div className="SUflexContainer">
          <div className="signUpInput selectCollegeDiv">
            <label className="college">College: *</label>
            <select name="college" value={inputs.college} onChange={handleChange} className="signUpInput selectYear" >
              <option value="" disabled hidden>Select College</option>
              {colleges.map((college) => (<option key={college} value={college}>{college}</option>))}
            </select>
          </div>
          <SignUpInput name="branch" labelName="Branch" value={inputs.branch} onChange={handleChange} required />
          <div className="signUpInput selectYearDiv">
            <label className="SItitle">Year: *</label>
            <select name="year" value={inputs.year} onChange={handleChange} className="signUpInput selectYear">
              <option value="" disabled hidden>Select Year</option>
              {years.map((year) => (<option key={year} value={year}>{year}</option>))}
            </select>
          </div>
        </div>

        {/* Team Members */}
        <div className="teamMembersContainer">
          <h3>Team Members</h3>
          {inputs.teamMembers.map((member, index) => (
            <div key={index} className="SUflexContainer teamMember">
              <SignUpInput name="name" labelName="Name" value={member.name} onChange={(e) => handleChange(e, index)} />
              <SignUpInput name="email" labelName="Email" type="email" value={member.email} onChange={(e) => handleChange(e, index)} />
              <SignUpInput name="contact" labelName="WhatsApp Number" type="tel" value={member.contact} maxLength="10" onChange={(e) => handleChange(e, index)} />
              {index > 0 && <button type="button" className="removeMemberBtn" onClick={() => removeMember(index)}>Remove</button>}
            </div>
          ))}
          {inputs.teamMembers.length < 3 && (
            <button type="button" className="addMemberBtn" onClick={addMember}>Add Member</button>
          )}
        </div>

        {/* Submit Button */}
        <button type="submit" className="submit" disabled={loading}>
          {loading ? "Registering..." : "Register"}
        </button>
      </form>
    </div>
  );
}
