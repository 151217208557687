import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom"; // ✅ Import useNavigate
import SpaceBackground from "../components/SpaceBackground";
import sponsorLogo from "../assets/images/ideathon/UptiqLogo2.png";
import sponsorLogoMain from "../assets/images/ideathon/UptiqLogoFinal.png";
import "../css/HackathonDetails.css";
import "../css/TsIdeathon.css";

const HackathonDetails = () => {
  const navigate = useNavigate(); // ✅ Define navigate function

  return (
    
    <div style={{
      minHeight: "100vh",
      backgroundColor: "#0a0a2a",
      color: "white",
      padding: "50px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: "100px", // Added padding
    }}>
      <div className="IdeathonSession1Logo">
          <img
            src={sponsorLogoMain}
            className="IdeathonSession1LogoImage"
            alt=""
          />
          <p className="ISLIpresents">Presents</p>
        </div>
        <p className="IdeathonSession1Title">AI Innovators Hackathon</p>
        <p className="IdeathonSession1SubTitle">Build with AI Agents!</p>
        <SpaceBackground />
    

      <motion.div 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 1.2, delay: 0.5 }}
        style={{
          backgroundColor: "#060b27",
        border: "5px solid #283e5f",   
        backdropFilter: "blur(10px)",
          padding: "30px",
          borderRadius: "12px",
          width: "80%", // Made it more responsive
          maxWidth: "800px",
          textAlign: "left",
          fontSize: "1.2rem"
        }}
      >
        <p><strong>🚀 AI Innovators Hackathon – Build with AI Agents! 🚀</strong></p>
  <p>
    Exciting AI Hackathon by <strong>UPTIQ.AI</strong>! Build innovative solutions on their AI agents platform and compete for <strong>₹60,000 in prizes! 🏆</strong>
  </p>

  <p><strong>🔥 Perks:</strong></p>
  <ul>
    <li><strong>✅</strong> Certificates from UPTIQ.AI</li>
    <li><strong>✅</strong> Networking with industry professionals</li>
    <li><strong>✅</strong> Internship opportunities for top performers!</li>
  </ul>

  <p><strong>📅 Important Dates:</strong></p>
  <ul>
  <li><strong>Registration Deadline:</strong>  20th February</li>
    <li><strong>Orientation:</strong> 23rd February</li>
    <li><strong>Online Screening Round:</strong> 26th-27th February</li>
    <li><strong>Final Round:</strong> 1st March</li>
  </ul>

  <p><strong>👥 Team Size:</strong>  2-4 members (Only the Team Leader has to register with team details)  </p>
  <p><strong>💰 Registration:</strong> 
  <ul>
    <li><strong>Free</strong> for Round 1</li>
    <li><strong>₹400</strong> per team for Final Round</li>
  </ul></p>

  <p><strong>Don’t miss out!</strong></p>
  <p>
    Click on Register Now Button 👇
  </p>

 
</motion.div>

      <motion.button 
        whileHover={{ scale: 1.1 }} 
        whileTap={{ scale: 0.9 }} 
        style={{
          marginTop: "30px",
          borderRadius: "8px",
          color: "white",
          fontSize: "1rem",
          border: "none",
          cursor: "pointer",
          position: "relative",
          zIndex: 10, 
          width: "180px",
          marginBottom: "50px" // Prevents overlap with footer
        }}
        className="button"
        onClick={() => navigate("/TechSummit25/HackathonRegistration")} // ✅ Corrected onClick
      >
        Register Now
      </motion.button>

    </div>
  );
};

export default HackathonDetails;
