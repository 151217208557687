import React from "react";
import "../css/homePage/HomePageTechSummitCard.css";
import HPTSimg from "../assets/images/homePage/HPTSimg.png";
import { Link } from "react-router-dom";

export default function HomePageTechSummitCard() {
  return (
    <div className="HPTScardContainer">
      <div className="HPTScard">
        <div className="HPTSflexContainer">
          <div className="HPTScardTitle">
            <h1 className="HPTSh1">TECH SUMMIT'25</h1>
            <p className="HTPScardTitlep">23rd February - 1st March</p>
          </div>
          <div className="HPTSimgContainer">
            <img src={HPTSimg} className="HPTSimg" alt="" />
          </div>
        </div>
        <div className="TechsummitSession3BtnContainer HPTSbtnContainer">
          <Link to="TechSummit25">
            <button className="TSS3button HPTSbtn">View More</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
