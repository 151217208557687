import React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SignUpInput from "./SignUpInput";
import "../css/signUp/SignUp.css";
import "../css/login/Login.css";
import "../css/signUp/SignUpMediaQuery.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "./Loader";

export default function ResetPasswordForm() {
  const [inputs, setInputs] = useState({
    //initial state of the form
    password: "",
    conf_password: "",
  });

  const [loader, setLoader] = useState(false); //for displaying the loader

  const notifySuccess = (
    e //function to display the success messages
  ) =>
    toast.success(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notify = (
    e //function to display the error messages
  ) =>
    toast.error(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "my-toast",
      bodyClassName: "my-toast-body",
    });

  const navigate = useNavigate(); //for navigation

  const { p1, p2 } = useParams();

  const handleChange = (event) => {
    //function to handle the change in the input fields
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const maxLengthCheck = (object) => {
    //function to check the maximum length of the input fields
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const passwordLengthRegex = /.{8,}/;
  const passwordLowercaseRegex = /.*[a-z]/;
  const passwordUppercaseRegex = /.*[A-Z]/;
  const passwordNumberRegex = /.*\d/;

  const handleSubmit = async (e) => {
    setLoader(true);
    //function to handle the submit event
    e.preventDefault();

    const { password } = inputs;

    let errorMessage = "";

    if (!passwordLengthRegex.test(password)) {
      errorMessage += "• Password must be at least 8 characters long.";
    }
    if (!passwordLowercaseRegex.test(password)) {
      errorMessage +=
        "\n\n• Password must contain at least one lowercase letter.";
    }
    if (!passwordUppercaseRegex.test(password)) {
      errorMessage +=
        "\n\n• Password must contain at least one uppercase letter.";
    }
    if (!passwordNumberRegex.test(password)) {
      errorMessage += "\n\n• Password must contain at least one number.";
    }

    if (errorMessage !== "") {
      notify(errorMessage);
      setLoader(false);
      return;
    }

    if (password !== inputs.conf_password) {
      notify("Passwords do not match");
      setLoader(false);
      return;
    }

    let body = JSON.stringify({
      //body of the request
      password,
    });

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        //post request to the backend
        "https://ieeevitpune.com/" + "user/resetPassword/" + p1 + "/" + p2,
        body,
        config
      );
      if (response.data.success === true) {
        //if the login is successful
        notifySuccess(response.data.msg); //display the success message
        navigate("/Login"); //navigate to the domain page
        // console.log(response);
      } else {
        //if the login is unsuccessful
        notify(response.data.msg); //display the error message
        // console.log(response);
      }
    } catch (err) {
      notify(err.response.data.msg);
      // console.log(err);
    }
    setLoader(false);
  };

  return (
    <>
      {loader && <Loader />}
      <div className="loginForm">
        <div className="SFtitle FPtitle">Reset Password</div>
        <form onSubmit={handleSubmit} className="form">
          <SignUpInput
            name="password"
            labelName="Password"
            type="password"
            className="lpassword"
            value={inputs?.password}
            onChange={handleChange}
            maxLengthCheck={maxLengthCheck}
          />
          <SignUpInput
            name="conf_password"
            labelName="Confirm Password"
            type="password"
            className="lpassword"
            value={inputs?.conf_password}
            onChange={handleChange}
            maxLengthCheck={maxLengthCheck}
          />
          <div className="submitContainer">
            <button className="submit" type="submit">
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
