import React from "react";
import "../css/LandingPage.css";
import Agenda from "../components/Agenda";
import { Link } from "react-router-dom";
import TsNavbar from "../components/TsNavbar";
import workshopImage from "../assets/images/homePage/WorkshopImage.jpg";
import ideathonImage from "../assets/images/homePage/ideathonImage2.png";
import CSI from "../assets/images/workshop/CSI.png";
import uptiq from "../assets/images/ideathon/UptiqLogoCropped.png";
import Landing from "../components/Landing";

export default function TsHomePage() {
  return (
    <>
      {/* <TsVideo /> */}
      <Landing/>
    
    </>
  );
}
