import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/signUp/SignUp.css";
import "../css/signUp/SignUpMediaQuery.css";
import SignUpInput from "./SignUpInput";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "./Loader";

export default function SignUpCard() {
  const [inputs, setInputs] = useState({
    //initial state of the form
    name: "",
    email: "",
    password: "",
    conf_password: "",
    graduation_year: "",
    phoneNumber: "",
    isMember: 0,
    membershipProof: "",
  });

  const [loader, setLoader] = useState(false); //for displaying the loader

  const [selectedFile, setSelectedFile] = useState(null); //state for the file upload

  const navigate = useNavigate(); //for navigation

  const [display, setDisplay] = useState(false); //for displaying the membership proof upload

  const handleChange = (event) => {
    //function to handle the change in the input fields
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const passwordLengthRegex = /.{8,}/;
  const passwordLowercaseRegex = /.*[a-z]/;
  const passwordUppercaseRegex = /.*[A-Z]/;
  const passwordNumberRegex = /.*\d/;

  const years = Array.from(
    //array of years for the graduation year dropdown
    { length: 5 },
    (_, i) => new Date().getFullYear() + i
  );

  const maxLengthCheck = (object) => {
    //function to check the maximum length of the input fields
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const notify = (
    e //function to display the error messages
  ) =>
    toast.error(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "my-toast",
      bodyClassName: "my-toast-body",
    });

  const handleFileChange = (event) => {
    //function to handle the change in the file upload
    setSelectedFile(event.target.files[0]);
    inputs.membershipProof = event.target.files[0];
  };

  const handleSubmit = async (event) => {
    setLoader(true);
    //function to handle the submission of the form
    event.preventDefault();
    const {
      name,
      email,
      password,
      graduation_year,
      phoneNumber,
      isMember,
      membershipProof,
    } = inputs;

    let errorMessage = "";

    if (!passwordLengthRegex.test(password)) {
      errorMessage += "• Password must be at least 8 characters long.";
    }
    if (!passwordLowercaseRegex.test(password)) {
      errorMessage +=
        "\n\n• Password must contain at least one lowercase letter.";
    }
    if (!passwordUppercaseRegex.test(password)) {
      errorMessage +=
        "\n\n• Password must contain at least one uppercase letter.";
    }
    if (!passwordNumberRegex.test(password)) {
      errorMessage += "\n\n• Password must contain at least one number.";
    }

    // console.log(inputs, selectedFile);

    if (errorMessage !== "") {
      notify(errorMessage);
      setLoader(false);
      return;
    }

    if (password !== inputs.conf_password) {
      notify("Passwords do not match");
      setLoader(false);
      return;
    }

    if (phoneNumber.length !== 10) {
      notify("Invalid phone number");
      setLoader(false);
      return;
    }

    if (
      isMember === -1 &&
      (membershipProof === "" || membershipProof === undefined)
    ) {
      notify("Please upload the membership proof");
      setLoader(false);
      return;
    }

    let body = new FormData();

    body.append("name", name);
    body.append("email", email);
    body.append("password", password);
    body.append("graduation_year", graduation_year);
    body.append("phoneNumber", phoneNumber);
    body.append("isMember", isMember);
    if (isMember === -1) {
      body.append("membershipProof", membershipProof);
    }

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await axios.post(
        "https://ieeevitpune.com/" + "user/register",
        body,
        config
      );

      if (response.data.success === false) {
        notify(response.data.msg);
      } else {
        localStorage.setItem("email", email);
        navigate("/verifyEmail");
      }
    } catch (err) {
      notify(err.response.data.msg);
    }
    setLoader(false);
  };

  const onKeyDown = (evt) => {
    //function to handle the keydown event
    (evt.key === "e" || evt.key === "." || evt.key === "-") &&
      evt.preventDefault();
  };

  const [isSelected, setIsSelected] = useState(false); //state for the checkbox

  const handleSelect = () => {
    //function to handle the checkbox
    if (inputs.email === "") {
      //if the email field is empty, display an error message
      notify("Please enter your email first");
      return;
    }
    setIsSelected(!isSelected); //toggle the checkbox
  };

  const handleVerify = async () => {
    //function to verify the email
    const { email } = inputs; //get the email from the state
    let body = {
      //create the body for the request
      email,
    };

    let config = {
      //create the config for the request
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      //send the request
      const response = await axios.post(
        "https://ieeevitpune.com/" + "user/checkMember",
        body,
        config
      );

      if (response.data.success === false) {
        //if the email is not registered, display an error message
        notify(response.data.msg);
      } else {
        //if the email is registered, set the isMember state to the response
        setInputs((values) => ({
          ...values,
          isMember: response.data.data.isMember, //set the isMember state to the response
        }));
      }
    } catch (err) {
      //if the request fails, display an error message
      notify(err.response.data.msg);
      setDisplay(false);
    }
  };

  useEffect(() => {
    //useEffect hook to handle the verification of the email
    if (inputs.email && isSelected) {
      //if the email is not empty and the checkbox is selected, call the handleVerify function
      handleVerify();
    }
    if (inputs.isMember === -1 && isSelected) {
      //if the email is not registered and the checkbox is selected, display the membership proof upload
      setDisplay(true);
    } else {
      //if the email is registered and the checkbox is selected, hide the membership proof upload
      setDisplay(false);
    }
    // eslint-disable-next-line
  }, [inputs.email, isSelected, inputs.isMember]);

  return (
    <>
      {loader ? <Loader /> : null}
      <div className="signUpForm">
        <div className="SFtitle">Sign Up</div>
        <form onSubmit={handleSubmit} className="form">
          <SignUpInput
            name="name"
            labelName="Full Name"
            value={inputs?.fullName}
            className="SUname"
            onChange={handleChange}
            maxLengthCheck={maxLengthCheck}
          />
          <div className="SUflexContainer">
            <SignUpInput
              name="email"
              labelName="Email Id"
              type="email"
              className="SUemail"
              value={inputs?.email}
              onChange={handleChange}
              maxLengthCheck={maxLengthCheck}
            />
            <SignUpInput
              name="phoneNumber"
              labelName="Phone Number"
              type="number"
              className="SUphoneNumber"
              value={inputs?.phoneNo}
              onKeyDown={onKeyDown}
              onChange={handleChange}
              maxLength="10"
              maxLengthCheck={maxLengthCheck}
            />
          </div>
          <div className="SUflexContainer">
            <SignUpInput
              name="password"
              labelName="Password"
              type="password"
              className="SUpassword"
              value={inputs?.password}
              onChange={handleChange}
              maxLengthCheck={maxLengthCheck}
            />
            <SignUpInput
              name="conf_password"
              labelName="Confirm Password"
              type="password"
              className="SUconfirmPassword"
              value={inputs?.conf_password}
              onChange={handleChange}
              maxLengthCheck={maxLengthCheck}
            />
          </div>
          <div className="signUpInput selectYearDiv">
            <label className="SItitle">Graduation Year: * </label>
            <select
              name="graduation_year"
              value={inputs?.graduation_year || ""}
              onChange={handleChange}
              className="signUpInput selectYear"
              required={true}
            >
              <option value="" disabled hidden>
                Select Year
              </option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="signUpInput ieeeMemberDiv">
            <input
              type="checkbox"
              onChange={handleSelect}
              value={!isSelected}
              name="membership"
              className="ieeeMember"
              checked={isSelected}
            />
            <label htmlFor="membership" className="SItitle ieeeMemberLabel">
              Are you a IEEE Member?
            </label>
          </div> */}
          {/* <div
            className={`signUpInput myFileContainer ${!display ? "hide" : ""}`}
          >
            <label htmlFor="membership" className="SItitle">
              Upload your IEEE Membership Proof *
            </label>
            <label className="custom-file-input">
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/png, image/jpeg, image/jpg"
              />
              <span className="custom-file-input-label">Choose file</span>
              {selectedFile && (
                <span className="selected-file-name">{selectedFile.name}</span>
              )}
            </label>
          </div> */}
          <div className="submitContainer">
            <button className="submit" type="submmit">
              Create an Account
            </button>
          </div>
          <div className="member">
            Already have an account?{" "}
            <Link to="/Login" className="SUlink">
              Login
            </Link>
          </div>
          <div className="verifyContainer">
            Account not verified?{" "}
            <Link to="/verifyEmail" className="SUlink verifyEmail">
              Verify Email
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
