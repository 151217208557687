import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/signUp/SignUp.css";
import "../css/signUp/SignUpMediaQuery.css";
import SignUpInput from "./SignUpInput";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "./Loader";

export default function OTPcard() {
  const [minutes, setMinutes] = useState(0); //state to store the minutes
  const [seconds, setSeconds] = useState(0); //state to store the seconds

  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false); //state to check if the button is clicked or not

  useEffect(() => {
    //useEffect to set the timer
    const interval = setInterval(() => {
      if (seconds > 0) {
        //if seconds is greater than 0 then decrement the seconds
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        //if seconds is 0 then decrement the minutes and set the seconds to 59
        if (minutes === 0) {
          //if minutes is 0 then clear the interval
          clearInterval(interval);
        } else {
          //else decrement the minutes and set the seconds to 59
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000); //set the interval to 1000ms

    return () => {
      //clear the interval when the component unmounts
      clearInterval(interval); //clear the interval
    };
  }, [seconds, minutes]);

  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    email: "",
    otp: "",
  });

  const notifyError = (
    e //function to display the error messages
  ) =>
    toast.error(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (
    e //function to display the success messages
  ) =>
    toast.success(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const { email, otp } = inputs;

    if (!clicked) {
      //if the button is not clicked
      if (email === "") {
        //if the email field is empty
        notifyError("Please enter your email id");
        return;
      }
      setInputs((values) => ({ ...values, email: email })); //set the email in the state
      resendOTPButton(event); //call the resendOTPButton function
      setClicked(true);
    } else {
      let body = {
        otp,
        email,
      };

      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios.post(
          "https://ieeevitpune.com/" + "user/verifyotp",
          body,
          config
        );
        if (response.status === 400) {
          // Invalid OTP
          notifyError(response.data.msg);
        } else {
          // Valid OTP
          if (response.data.success === true) {
            // OTP verified
            navigate("/Login", { replace: true });
            notifySuccess(response.data.msg);
          } else {
            // OTP not verified
            notifyError(response.data.msg);
          }
        }
      } catch (err) {
        // Server error
        notifyError(err.response.data.msg);
      }
    }
    setLoading(false);
  };

  const resendOTPButton = async (event) => {
    setMinutes(2);
    setSeconds(0);
    await toast.promise(
      resendOTP(event),
      {
        pending: "Sending OTP...",
      },
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  };

  const resendOTP = async (event) => {
    setLoading(true);
    event.preventDefault();

    let body = {
      email: inputs.email,
    };

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        "https://ieeevitpune.com/" + "user/resendOtp",
        body,
        config
      );
      if (response.data.success === true) {
        // OTP verified
        // console.log("OTP sent successfully");
        notifySuccess(response.data.msg);
      } else {
        // OTP not verified
        notifyError(response.data.msg);
        // console.log(response);
      }
    } catch (err) {
      // Server error
      notifyError(err.response.data.msg);
      // console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setInputs((values) => ({
        ...values,
        email: localStorage.getItem("email"),
      }));
      setClicked(true);
    }
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="loginForm">
        <div className="SFtitle">Verify Email</div>
        <form onSubmit={handleSubmit} className="form">
          <SignUpInput
            name={!clicked ? "email" : "otp"}
            labelName={!clicked ? "Email Id" : "Enter OTP"}
            type={!clicked ? "email" : "text"}
            className="lemail"
            value={!clicked ? inputs?.email : inputs?.otp}
            onChange={handleChange}
            maxLengthCheck={maxLengthCheck}
          />
          <div className="submitContainer">
            <button className="submit" type="submit">
              {!clicked ? "Get OTP" : "SUBMIT"}
            </button>
          </div>
          {/* <p className="otp">The OTP has been sent to {email}</p>
          <SignUpInput
            name="otp"
            labelName="Enter OTP"
            type="text"
            className="lemail"
            value={inputs?.otp}
            onChange={handleChange}
            maxLengthCheck={maxLengthCheck}
            maxLength="6"
          /> */}
          {/* <div className="submitContainer">
            <button className="submit" type="submit">
              SUBMIT
            </button>
          </div> */}
          <div className="member timer">
            {seconds > 0 || minutes > 0 ? (
              <p className="timerCounter">
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            ) : (
              <p className="timerCounter">Didn't recieve the OTP?</p>
            )}
            <button
              disabled={seconds > 0 || minutes > 0}
              onClick={resendOTPButton}
              className="SUlink otpLink"
            >
              Resend OTP
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
